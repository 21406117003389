import * as React from 'react';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { Typography, Checkbox, Tooltip } from '@mui/material';
import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material';

import { getHelperText } from 'modules/outbound/utils';

export interface HeadCell {
  checked?: boolean;
  displayType?: 'checkbox' | 'text';
  id: string;
  textAlign?: string;
  label: string;
  numeric: boolean;
  sortable: boolean;
  width?: string;
  backgroundColor?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

type Order = 'asc' | 'desc';

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  headCells: HeadCell[];
  sx?: object;
}

const EnhancedTableHead = ({ headCells, order, orderBy, onRequestSort, sx }: EnhancedTableProps) => {
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  const renderCellContent = (cell: HeadCell) => {
    switch (cell.displayType) {
      case 'checkbox':
        return (
          <TableCell
            width={cell.width}
            key={cell.id}
            align={cell.numeric ? 'right' : cell.textAlign ? 'center' : 'left'}
            sortDirection={orderBy === cell.id ? order : false}
            sx={{ bgcolor: '#0e48a2', height: 45, verticalAlign: 'middle', color: '#fff', ...sx }}
          >
            <Checkbox key={cell.id} checked={cell.checked} onChange={cell.onChange} />
          </TableCell>
        );
      default:
        return (
          <TableCell
            width={cell.width}
            key={cell.id}
            align={cell.numeric ? 'right' : cell.textAlign ? 'center' : 'left'}
            sortDirection={orderBy === cell.id ? order : false}
            sx={{ bgcolor: '#0e48a2', height: 45, verticalAlign: 'middle', color: '#fff', ...sx }}
          >
            {cell.sortable ? (
              <TableSortLabel
                active={orderBy === cell.id}
                direction={orderBy === cell.id ? order : 'asc'}
                onClick={createSortHandler(cell.id)}
              >
                {getHelperText(cell.label) !== '' ? (
                  <Box display="flex" gap="15px" alignItems={'flex-end'}>
                    <Typography variant="body2" sx={{ fontWeight: 600 }}>
                      {cell.label}
                    </Typography>
                    <Tooltip title={getHelperText(cell.label) || ''}>
                      <InfoOutlinedIcon
                        sx={{
                          width: '15px',
                          height: '15px',
                        }}
                        color="action"
                      />
                    </Tooltip>
                  </Box>
                ) : (
                  <Typography variant="body2" sx={{ fontWeight: 600 }}>
                    {cell.label}
                  </Typography>
                )}

                {orderBy === cell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : getHelperText(cell.label) !== '' ? (
              <Box display="flex" gap="15px" alignItems={'flex-end'}>
                <Typography variant="body2" sx={{ fontWeight: 600 }}>
                  {cell.label}
                </Typography>
                <Tooltip title={getHelperText(cell.label) || ''}>
                  <InfoOutlinedIcon
                    sx={{
                      width: '15px',
                      height: '15px',
                    }}
                    color="action"
                  />
                </Tooltip>
              </Box>
            ) : (
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                {cell.label}
              </Typography>
            )}
          </TableCell>
        );
    }
  };

  return (
    <TableHead>
      <TableRow>{headCells.map(renderCellContent)}</TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
