//icons &  components
import { HeadCell } from 'modules/shared/components/table/components/EnhancedTableHead.component';
import { BodyCell } from 'modules/shared/components/table/Table.component';
import Visibility from 'assets/icons/Union.svg';
//utils
import { endOfMonth, nextSunday, setDate, sub } from 'date-fns';
import { getStatusIcon, getStatusImage, getStatusChip } from 'utils/getRequestsIconsStatus';
// interfaces
import {
  RequestInterface,
  OperatorInterface,
  FiltersInterface,
  LicenseInterface,
  FilterQueryObjectInterface,
} from 'modules/requests/interfaces/request.interface';
import { LicensesNames } from 'modules/shared/interfaces';
import { TypeModal } from 'modules/shared/components/modal/Modal.component';
import { dateToString } from 'utils/dateToString';

export const getHeadCells = (isSupervisor: boolean): HeadCell[] => {
  const headCells: HeadCell[] = [
    {
      id: '',
      label: '',
      numeric: false,
      sortable: false,
    },
    {
      id: 'type',
      label: 'Tipo de novedad',
      numeric: false,
      sortable: false,
    },
    {
      id: 'operator',
      label: 'Apellido, Nombre',
      numeric: false,
      sortable: false,
    },
    {
      id: 'id',
      label: 'ID',
      numeric: false,
      sortable: false,
    },
    {
      id: 'from',
      label: 'Desde',
      numeric: false,
      sortable: false,
    },
    {
      id: 'to',
      label: 'Hasta',
      numeric: false,
      sortable: false,
    },
    {
      id: 'news_state',
      label: 'Estado',
      numeric: false,
      sortable: false,
    },
    {
      id: 'justify_state',
      label: '',
      numeric: false,
      sortable: false,
    },
    { id: 'action2', label: '', numeric: false, sortable: false, width: '70px' },
  ];
  if (isSupervisor) {
    headCells.unshift({
      id: 'checkbox',
      label: '',
      numeric: false,
      sortable: false,
    });
  }
  return headCells;
};

const fullName = (personInCharge?: OperatorInterface | undefined) => {
  if (!personInCharge) return '-';

  const fullName = `${personInCharge.names} ${personInCharge.surenames}`;

  return fullName;
};
interface RequestListProps {
  sortedRequests: RequestInterface[];
  onClickAttachment: (request: RequestInterface) => void;
  canReadRequests: boolean;
  onSelectRow: (id: string) => void;
  canUploadFile?: boolean;
  selected: string[];
  isSupervisor: boolean;
}

export const requestsToBodyCell: ({
  sortedRequests,
  onClickAttachment,
  canReadRequests,
  onSelectRow,
  selected,
  isSupervisor,
}: RequestListProps) => BodyCell[][] = ({
  sortedRequests,
  canReadRequests,
  onSelectRow,
  selected,
  isSupervisor,
}: RequestListProps) => {
  function DNIformmatedWithDots(dni: number | string): string {
    const dniTostring = dni.toString();
    const dniBeforeChanges = dniTostring.split('').reverse();
    let dniWithDots = '';
    for (let i = 0; i < dniBeforeChanges.length; i++) {
      if (i % 3 == 0 && i != 0) dniWithDots = '.' + dniWithDots;
      dniWithDots = dniBeforeChanges[i] + dniWithDots;
    }
    return dniWithDots;
  }

  return sortedRequests.map((request: RequestInterface) => {
    const parsedRequest: BodyCell[] = [
      {
        displayType: 'icon',
        icon: getStatusImage(request.requestStatus[0].statusId),
      },
      { displayType: 'text', text: request.license.name, bold: request.requestStatus[0].statusId === 5 && true },
      {
        displayType: 'text',
        text: fullName(request.operator),
        bold: request.requestStatus[0].statusId === 5 && true,
      },
      {
        displayType: 'text',
        text: request.operator && request.operator.dni ? DNIformmatedWithDots(request.operator.dni) : '-',
        bold: request.requestStatus[0].statusId === 5 && true,
      },

      {
        displayType: 'text',
        text: `${dateToString(request.start_date)}`,
        bold: request.requestStatus[0].statusId === 5 && true,
      },
      {
        displayType: 'text',
        text: `${dateToString(request.end_date)}`,
        bold: request.requestStatus[0].statusId === 5 && true,
      },
      {
        displayType: 'icon',
        icon: getStatusChip(request.requestStatus[0].statusId),
      },
      {
        displayType: 'icon',
        icon: getStatusIcon(request.requestStatus[0].statusId),
      },
      {
        displayType: 'anchor',
        icon: canReadRequests ? <img src={Visibility} style={{ width: '25px', marginTop: '8px' }} /> : undefined,
        href: canReadRequests ? `/requests/${request.id}` : undefined,
      },
    ];
    if (isSupervisor) {
      parsedRequest.unshift({
        displayType: 'checkbox',
        disabled: request.requestStatus[0].statusId !== 5,
        checked: request.id ? selected.includes(request.id) : false,
        onClick: () => request.id && onSelectRow(request.id),
      });
    }
    return parsedRequest;
  });
};

export const mapFiltersToQueryParams = (filters: FiltersInterface): FilterQueryObjectInterface => {
  const queryParams: FilterQueryObjectInterface = {};

  if (filters.column === 'Fecha') {
    switch (filters.condition) {
      case 'month':
        {
          const lastMonthStartDate = setDate(new Date(), 1);
          const lastMonthStartOnYYYYMMDD = lastMonthStartDate.toISOString().split('T')[0];
          const lastMonthEndDate = sub(endOfMonth(new Date()), { days: 1 });
          const lasMonthEndOnYYYYMMDD = lastMonthEndDate.toISOString().split('T')[0];
          queryParams['start_date'] = lastMonthStartOnYYYYMMDD;
          queryParams['end_date'] = lasMonthEndOnYYYYMMDD;
        }
        break;
      case 'week':
        {
          const lastWeekDate = new Date();
          const daysToSubtract = lastWeekDate.getDay() - 1;
          lastWeekDate.setDate(lastWeekDate.getDate() - daysToSubtract);
          const lastWeekStartOnYYYYMMDD = lastWeekDate.toISOString().split('T')[0];
          const lastWeekEndDate = nextSunday(new Date());
          const lastWeekEndOnYYYYMMDD = lastWeekEndDate.toISOString().split('T')[0];
          queryParams['start_date'] = lastWeekStartOnYYYYMMDD;
          queryParams['end_date'] = lastWeekEndOnYYYYMMDD;
        }
        break;
      case 'day':
        {
          const todayAtFirstSecond = new Date();
          todayAtFirstSecond.setHours(0, 0, 0, 0);
          const todayOnYYYYMMDD = todayAtFirstSecond.toISOString().split('T')[0];
          const todayEndOnYYYYMMDD = new Date().toISOString().split('T')[0];
          queryParams['start_date'] = todayOnYYYYMMDD;
          queryParams['end_date'] = todayEndOnYYYYMMDD;
        }
        break;
      case 'period':
        {
          if (filters.value !== '') {
            // Regular expression to match date strings
            const dateRegex = /([A-Za-z]{3} [A-Za-z]{3} \d{1,2} \d{4} \d{2}:\d{2}:\d{2} GMT[-+]\d{4} \([A-Za-z\s]+\))/g;

            // Extract date strings from the input
            const dateStrings = filters.value.match(dateRegex);

            if (dateStrings && dateStrings.length >= 2) {
              // Parse and format the extracted date strings
              const date1 = new Date(dateStrings[0]);
              const date2 = new Date(dateStrings[1]);

              const startDateOnYYYYMMDD = date1.toISOString().split('T')[0];
              const endDateOnYYYYMMDD = date2.toISOString().split('T')[0];

              queryParams['start_date'] = startDateOnYYYYMMDD;
              queryParams['end_date'] = endDateOnYYYYMMDD;
            }
          }
        }
        break;
      default:
        break;
    }
  }

  if (filters.column === 'Tipo de novedad') {
    if (filters.condition) {
      queryParams['typeLicenses'] = filters.condition;
      queryParams['status'] = filters.value;
    }
  }

  if (filters.column === 'Empleado') {
    if (filters.condition) queryParams['operators'] = filters.condition;
  }

  if (filters.column === 'Estado de la novedad' || filters.column === 'Estado del certificado') {
    if (filters.condition) queryParams['status'] = filters.condition;
  }
  return queryParams;
};

export const isValidDate = (d: any) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return d instanceof Date && !isNaN(d);
};

export const subtractMonths = (numOfMonths: number, date: Date = new Date()) => {
  date.setMonth(date.getMonth() - numOfMonths);
  return date;
};

export const addMonths = (numOfMonths: number, date: Date = new Date()) => {
  date.setMonth(date.getMonth() + numOfMonths);
  return date;
};

// todo -> make this function to return an array of string,
export const getTextBasedOnRequestId = (license: LicenseInterface): string[] => {
  switch (license.name) {
    case LicensesNames.VACATION:
      return [
        `Al seleccionar la fecha ten presente que solo cuentas con 10 días de corrido`,
        `Tu solicitud figurará como Pendiente hasta que el responsable asignado la revise y apruebe o rechace`,
        `Solo podrás editar la novedad si el responsable asignado aún no ha ingresado a revisarla`,
      ];
    case LicensesNames.GREMIAL_PERMISSION:
      return [
        `No puedes tomarte más de 1 día hábil`,
        `Tu solicitud figurará como Pendiente hasta que el responsable asignado la revise y apruebe o rechace`,
        `Solo podrás editar la novedad si el responsable asignado aún no ha ingresado a revisarla`,
      ];
    case LicensesNames.JOB_RESERVATION:
      return [
        `Tu solicitud figurará como Pendiente hasta que el responsable asignado la revise y apruebe o rechace`,
        `Solo podrás editar la novedad si el responsable asignado aún no ha ingresado a revisarla`,
      ];
    case LicensesNames.ABSENT_WITHOUT_NOTIFICATION:
      return [
        `Contemplar la carta documento para poder realizar un seguimiento desde el sistema de notificaciones sobre esta novedad`,
        `Tu solicitud figurará como Pendiente hasta que el responsable asignado la revise y apruebe o rechace`,
        `Solo podrás editar la novedad si el responsable asignado aún no ha ingresado a revisarla`,
      ];
    case LicensesNames.BLOOD_DONATION:
      return [
        'Tu solicitud figurará como Pendiente hasta que el responsable asignado la revise y apruebe o rechace',
        'Solo podrás editar la novedad si el responsable asignado aún no ha ingresado a revisarla',
      ];
    case LicensesNames.EXAM:
      return [
        `Te quedan 15 días de examen para el período 2023`,
        `Tu solicitud figurará como Pendiente hasta que el responsable asignado la revise y apruebe o rechace`,
        `Solo podrás editar la novedad si el responsable asignado aún no ha ingresado a revisarla`,
      ];
    case LicensesNames.PATERNITY_LICENSE:
      return [
        `Te puedes tomar hasta 3 días corridos desde la fecha de nacimiento`,
        `Tu solicitud figurará como Pendiente hasta que el responsable asignado la revise y apruebe o rechace`,
        `Solo podrás editar la novedad si el responsable asignado aún no ha ingresado a revisarla`,
      ];
    case LicensesNames.MOVING:
      return [
        `Solo podrás editar la novedad si el responsable asignado aún no ha ingresado a revisarla`,
        `El justificativo puede ser el contrato de alquiler o un servicio a tu nombre`,
        `Asegúrate de que la imagen cumpla las siguientes recomendaciones:
          · Que tenga calidad, enfocada y legible
          · Formatos PDF, JPG, o PNG
          · Peso máximo de 25MB`,
      ];
    case LicensesNames.PROCEDURES:
    case LicensesNames.MOURNING:
    case LicensesNames.FAMILY_ILLNESS:
    case LicensesNames.UNPAID_LICENSE:
    case LicensesNames.SUSPENSION:
      return [
        `Tu solicitud figurará como Pendiente hasta que el responsable asignado la revise y apruebe o rechace`,
        `Solo podrás editar la novedad si el responsable asignado aún no ha ingresado a revisarla`,
      ];
    case LicensesNames.ART:
    case LicensesNames.DISEASE:
      return [
        `Al seleccionar la fecha ten presente que solo cuentas con 10 días de corrido`,
        `Tu solicitud figurará como Pendiente hasta que el responsable asignado la revise y apruebe o rechace`,
        `Solo podrás editar la novedad si el responsable asignado aún no ha ingresado a revisarla`,
      ];
    case LicensesNames.MATERNITY_LICENSE:
      return [
        `La fecha probable de parto debe coincidir con la indicada en el certificado médico`,
        `Puedes solicitar hasta 45 días antes de la fecha probable de parto`,
        `Tu solicitud figurará como Pendiente hasta que el responsable asignado la revise y apruebe o rechace`,
        `Solo podrás editar la novedad si el responsable asignado aún no ha ingresado a revisarla`,
      ];
    case LicensesNames.MATERNITY_LICENSE_2:
      return [
        `Puedes solicitar hasta 45 días después de la fecha de parto`,
        `Tu solicitud figurará como Pendiente hasta que el responsable asignado la revise y apruebe o rechace`,
        `Solo podrás editar la novedad si el responsable asignado aún no ha ingresado a revisarla`,
      ];
    case LicensesNames.MARRIAGE_LICENSE:
      return [
        `Te quedan 15 días de examen para el período 2023`,
        `Tu solicitud figurará como Pendiente hasta que el responsable asignado la revise y apruebe o rechace`,
        `Solo podrás editar la novedad si el responsable asignado aún no ha ingresado a revisarla`,
      ];
    case LicensesNames.EXTRA_HOURS:
      return [
        `Al agregar más de un día, asegúrate de mantener el mismo horario.`,
        `Especifique el horario de las horas extras a partir de su hora de salida habitual.`,
      ];
    case LicensesNames.SHIFT_CHANGE:
      return [
        `Al agregar más de un día, asegúrate de mantener el mismo horario.`,
        `Ten en cuenta seleccionar el rango de horas que va desde el inicio hasta el final del turno.`,
      ];
    default:
      return [];
  }
};

export const initialDataModal = {
  isOpenModal: false,
  title: '',
  content: '',
  loadingBtnConfirm: false,
  typeModal: TypeModal.ERROR,
  textBtnConfirm: 'Anular',
  hasError: false,
};

export const initialAvailableActions = {
  edit: false,
  delete: false,
  cancel: false,
  create: false,
  uploadFile: false,
};
